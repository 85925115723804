button {
    border: 0;
    background-color: $clr1;
    color: whitesmoke;

    cursor: pointer;
}

form > * {
    display: block;
    width: 200px;
    margin: 10px 0px;
}

.btn-link {
    border: 0;
    background-color: transparent;
    text-decoration: underline;
    transition: 0.5s;
}
.btn-link:hover {
    background-color: $clr1;
    transform: scale(1.1);
}
