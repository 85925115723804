* {
    box-sizing: border-box;
}

::selection {
    background-color: $clr1;
    color: $clr6;
}

html {
    font-family: montserrat;
    line-height: 1.6;
    color: $clr2;
}

body {
    font-size: rem(18px);
}

section,
main,
footer {
    padding: 5px;
}

img {
    width: 100%;
}

p {
    margin: 0 0 1em;
}

a {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
