// Colors
$clr1: #ce2e6c;
$clr2: #4f3a65;
$clr3: #574f7d;
$clr4: #95adbe;
$clr5: #dff0ea;
$clr6: #fff;
$clr7: #222;


// Breakpoints
$mobile-breakpoint: 460px;
$narrow-breakpoint: 720px;
$normal-breakpoint: 960px;